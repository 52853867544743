<template>
    <v-container class="pa-2 my-2" fluid>
        <v-autocomplete
            :items="items"
            :item-text="formItem.autocomplete.text"
            :item-value="formItem.autocomplete.value"
            :label="formItem.label"
            :readonly="formItem.readonly || loading"
            :search-input.sync="search"
            :value="selected"
            v-model="selected"
            :loading="loading"
            cache-items
        >
            <template v-slot:item="{ item }">
                <v-list-item-avatar v-if="!formItem.autocomplete.icon"
                    color="primary"
                    class="text-h9 font-weight-light white--text">
                    {{ item[formItem.autocomplete.text].substring(0, 3) }}
                </v-list-item-avatar>
                <v-list-item-icon v-if="formItem.autocomplete.icon">
                    <v-icon>{{ formItem.autocomplete.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item[formItem.autocomplete.text] }}</v-list-item-title>
                    <v-list-item-subtitle> {{ item[formItem.autocomplete.value] }}</v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </v-container>
</template>

<script>
    import { common, ajax } from '../mixins/common'
    export default {
        mixins: [ common ],
        data: function () {
            return {
                search: '',
                selected: '',
                autoCompleteData: [],
                items: [],
                loading: true
            }
        },
        props: {
            formItem: {
                type: Object
            },
            defaultValue: {
                type: [ Object, String, Number ]
            },
            formUrl: {
                type: String
            }
        },
        watch: {
            // set url for the auto complete inforation source
            formUrl: {
                immediate: true,
                handler (val) {
                    if (typeof val !== "undefined") {
                        this.getAutoCompleteData(val)
                    }
                }
            },
            // set the default value of the auto complete form
            defaultValue: {
                handler (val) {
                    if (typeof val !== "undefined") {
                        this.selected = val
                    }
                }
            },
            // filter the items of the source for matches
            search: {
                handler (val) {      
                    !this.loading && val && val !== this.select && this.querySelections(val)
                }
            },
            // send the selected value back to parent form
            selected: {
                handler (val) {
                    this.$emit('formSelection', val)
                }
            }
        },
        methods: {
            querySelections (v) {
                this.items = this.autoCompleteData.filter(e => {
                    return (e[this.acText] || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
                })
            },
            getAutoCompleteData(url) {
                ajax.get(url, {})
                    .then(response => {
                        this.loading = false
                        this.items = response.data
                        this.autoCompleteData = response.data
                    })
                    .catch(e => {
                        console.log(e)
                        this.error(e)
                    })
            },
        }
    }
</script>