import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-2 my-3",attrs:{"fluid":""}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltip)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("fa-question-circle")])]}}],null,false,3226189860)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):_vm._e()],1),_c(VLayout,{attrs:{"wrap":""}},_vm._l((_vm.items),function(n){return _c(VFlex,{key:n.value,attrs:{"xs12":"","sm4":"","md3":""}},[_c(VCheckbox,{attrs:{"label":n.name,"value":n.value},model:{value:(_vm.checkedBoxes),callback:function ($$v) {_vm.checkedBoxes=$$v},expression:"checkedBoxes"}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }