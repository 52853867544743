import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFlex,[_c(VNavigationDrawer,{attrs:{"mini-variant":_vm.mini,"mini-variant-width":"78","permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c(VToolbar,{attrs:{"flat":"","dark":"","color":"primary"}},[_c(VToolbarTitle,{on:{"click":function($event){return _vm.nav(_vm.path)}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c(VIcon,[_vm._v("fa-chevron-left")])],1)],1),_c(VDivider),_c(VList,{staticClass:"pt-0",attrs:{"dense":""}},_vm._l((_vm.pages),function(item){return _c(VListItem,{key:item.title,on:{"click":function($event){return _vm.nav(item.path)}}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.capitalizeFirstLetter(item.title)))])],1)],1)}),1),_c('div',{staticClass:"text-caption text--disabled light"},[_vm._v("content: "+_vm._s(_vm.contentVersion))]),_c('div',{staticClass:"text-caption text--disabled light"},[_vm._v("software: "+_vm._s(_vm.softwareVersion)+_vm._s(_vm.env === 'production' ? '' : `-${_vm.env}`))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }