import axios from 'axios'
//import { setupCache } from 'axios-cache-adapter'
import constants from './constants'

// Create `axios-cache-adapter` instance
//const cache = setupCache({
//  maxAge: 15 * 60 * 1000
//})

export const HTTP_AUTH = axios.create({
  baseURL: constants.authTokenEndpoint,
  timeout: constants.connectionTimeout,
  method: 'post',
  responseType: 'json'
})

export const ajax = axios.create({
  //adapter: cache.adapter,
  timeout: constants.connectionTimeout,
	responseType: 'json'
 })

export const ajaxFile = axios.create({
  //adapter: cache.adapter,
  timeout: constants.connectionTimeout,
  responseType: 'blob'
 })

ajax.CancelToken = axios.CancelToken
ajax.isCancel = axios.isCancel

ajaxFile.CancelToken = axios.CancelToken
ajaxFile.isCancel = axios.isCancel

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
ajax.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access_token')

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }

    return config
  },

  (error) => {
    return Promise.reject(Error(error))
  }
)

ajaxFile.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access_token')

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }

    return config
  },

  (error) => {
    return Promise.reject(Error(error))
  }
)

export default ajax