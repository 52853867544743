<template>
    <v-container v-if="subtitle" class="ma-1" fluid>
        <router-link :to="subtitleLink">
            <h3>{{ subtitleDisplay }}<v-icon class="pa-2">fa-level-up-alt</v-icon></h3>
        </router-link>
    </v-container>
</template>

<script>
    import { common, ajax } from '../mixins/common'
    export default {
        mixins: [ common ],
        name: 'BaseSubtitle',
        data: () => ({
            subtitleDisplay: ''
        }),
        props: {
            id: {
                type: String
            },
            subtitle: {
                type: Object
            }
        },
        mounted: function () {
            this.updateSubtitleDisplay()
        },
        watch: {
            '$route': {
                handler () {
                    this.updateSubtitleDisplay()
                }
            }
        },
        methods: {
            updateSubtitleDisplay() {
                if (this.subtitle.url) {
                    ajax.get(this.url(this.subtitle.url, this.id), {})
                    .then(response => {
                        this.subtitleDisplay = response.data[this.subtitle.value]
                    })
                    .catch(e => {
                        this.$emit('error', e)
                    })
                } else {
                    this.subtitleDisplay = this.subtitle.value
                }
            }
        },
        computed: {
            subtitleLink: function() {
                return this.url(this.subtitle.link, this.id)
            }
        }
    }
</script>
