<template>
  <v-card outlined class="mx-auto">
    <v-card-text>
      <v-list two-line>
        <div v-for="(item, index) in items" :key="index">
          <template v-if="showListItem(item, data)">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              avatar
            >
              {{ item.header }}
            </v-subheader>
            <v-divider :key="index"></v-divider>
            <v-list-item v-if="!item.header"
              :key="item.title"
              @click="listItemClicked(item)">
              <v-list-item-avatar>
                <v-icon large>{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{  item.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import { common, ajax } from '../mixins/common'
  import { Buffer } from "buffer"
  export default {
    mixins: [ common ],
    data: function () {
        return {
            data: {},
            items: []
        }
    },
    props: {
      id: {
          type: String
      },
      list: {
          type: Object
      }
    },
    mounted: function () {
      if (typeof this.list.url == "string") {
        let url = this.url(this.list.url, this.id)
        this.getListData(url)
      }
      if (typeof this.list.items == "object") {
        this.items = this.list.items
      }
    },
    methods: {
      showListItem(item, data) {
        return this.showIf(item, data)
      },
      getListData(url) {
        ajax.get(url)
          .then(response => {
            this.data = response.data
            this.$emit('data', this.data)
          })
            .catch(e => {
              this.error(e)
          })
      },
      getItemData(url, item, value) {
        ajax.get(url)
          .then(response => {
            this.dummy(item, value, response.data)
          })
            .catch(e => {
              this.error(e)
          })
      },
      listItemClicked (item) {
        // make sure the page id is in the list data
        // so that it can be used in url building
        // note the if the page id is different than
        // the list data id, then the list data id
        // is getting replaced!  This is problemeatic
        this.data['id'] = this.id
        if (typeof item.path == 'object') {
          if (typeof item.path.url != 'undefined') {
            let url = this.url(item.path.url, this.id)
            this.getItemData(url, item, this.id);
          } else {
            this.dummy(item, this.id)
          }
        } else {
          // link/launch/go off to a new page when the button is clicked
          this.go(this.url(item.path, this.data))
        }
      },
      dummy(item, value, data) {
        if (typeof item.path.get !== "undefined") {
          // a get request with parameters on the button push
          let url = this.url(item.path.get, value)
          ajax.get(url)
              .then(response => {
                if (typeof response.data.message != "undefined") {
                  this.$emit('dialog', "Success!", response.data.message)
                } else if (typeof response.data != "undefined") {
                  this.$emit('dialog', "Success!", response.data)
                } else {
                  this.$emit('dialog', "Success!")
                }
            })
              .catch(e => {
                this.error(e)
            })
        } else if (typeof item.path.put !== "undefined") {
          // a put request with parameters in the payload on the button push
          let url = this.url(item.path.put, value)
          let payload = {}
          if (item.path.payload) {
            payload = this.replaceParams(item.path.payload, data)
          }
          ajax.put(url, payload)
            .then(response => {
              if (typeof response.data.message != "undefined") {
                this.$emit('dialog', "Success!", response.data.message)
              } else if (typeof response.data != "undefined") {
                this.$emit('dialog', "Success!", response.data)
              } else {
                this.$emit('dialog', "Success!")
              }
            })
              .catch(e => {
                this.error(e)
            })
        } else if (typeof item.path.post !== "undefined") {
          // a post request with parameters in the payload on the button push
          let url = this.url(item.path.post, value)
          let payload = {}
          if (item.path.payload) {
            payload = this.replaceParams(item.path.payload, data)
          }
          ajax.post(url, payload, { responseType: 'arraybuffer' })
              .then(response => {
                if (response.headers['content-type'] == 'image/png') {
                  // response is an image
                  var imgBase64 = Buffer.from(response.data, 'binary').toString('base64')
                  this.$emit('dialog', "Success!", "<img src='data:" + response.headers['content-type'] + ";base64," + imgBase64 + "' />")
                } else if (response.headers['content-type'] == 'text/html; charset=UTF-8') {
                  // response is text
                  var enc = new TextDecoder("utf-8");
                  var arr = new Uint8Array(response.data);
                  this.$emit('dialog', "Success!", enc.decode(arr))
                } else {
                  this.$emit('dialog', "Success!", response.headers['content-type'])
                }
            })
              .catch(e => {
                this.error(e)
            })
        }
      }
    }
  }
</script>
