import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import App from './App.vue'
import router from './router'
import auth from './auth'
import Clipboard from 'v-clipboard'

import 'vuetify/dist/vuetify.min.css'

const opts = {
  icons: { iconfont: 'fa' },
  theme: { 
    light: true,
    themes: {
      light: {
        primary: process.env.VUE_APP_COLOR_PRIMARY
      }
    }
  }
}

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

Vue.use(Vuetify)
Vue.use(auth)
Vue.use(Clipboard)

Vue.config.productionTip = false
Vue.prototype.$error = new Error()

new Vue({
  vuetify: new Vuetify(opts),
  router,
  render: h => h(App)
}).$mount('#app')
