<template>
  <div class="text-center">
    <v-layout align-center justify-center>
      <v-bottom-sheet v-model="sheet" inset>
        <v-card color="error lighten-2" class="text-center pa-4">
          <h2>
            <v-icon large>fa-exclamation-triangle</v-icon>
            &nbsp;&nbsp;
            {{ errorCode }}:{{ errorMessage }}
            &nbsp;&nbsp;
            <v-btn v-on:click.native="moreInfo()" color="error">more info</v-btn>
          </h2>
        </v-card>
      </v-bottom-sheet>
    </v-layout>
  </div>
</template>

<script>
    export default {
      name: 'BaseError',
      data: () => ({
        sheet: false,
        errorCode: '',
        errorMessage: '',
        errorData: {}
      }),
      methods: {
        moreInfo() {
          this.$emit('dialog', 'Details of Error', this.errorData)
          this.sheet = false
        }
      },
      watch: {
        error: function () {
          if (this.error != null) {
            if (this.error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.errorCode = this.error.response.status
              this.errorMessage = this.error.message
              this.errorData = this.error.response.data
            } else if (this.error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              this.errorCode = 'Timeout'
              this.errorMessage = 'No response received from the server'
              this.errorData = this.error.request
            } else {
              // Something happened in setting up the request that triggered an Error
              this.errorCode = 'Client Error'
              this.errorMessage = this.error.message
              this.errorData = ''
            }
            this.sheet = true
          }
        } 
      },
      props: {
        error: {
          type: Error,
        }
      }
    }
</script>
