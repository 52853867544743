<template>
  <v-flex>
    <v-navigation-drawer
      :mini-variant.sync="mini"
      mini-variant-width="78"
      permanent>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title @click="nav(path)">
          <v-icon large>{{ icon }}</v-icon>&nbsp;{{ title }}
        </v-toolbar-title>
        <v-btn icon @click.stop="mini = !mini"><v-icon>fa-chevron-left</v-icon></v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-item
            v-for="item in pages"
            :key="item.title"
            @click="nav(item.path)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          
          <v-list-item-content>
            <v-list-item-title>{{ capitalizeFirstLetter(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <div class="text-caption text--disabled light">content: {{ contentVersion }}</div>
      <div class="text-caption text--disabled light">software: {{ softwareVersion }}{{ env === 'production' ? '' : `-${env}` }}</div>
    </v-navigation-drawer>
</v-flex>
</template>

<style scoped>
  .tile {
    margin: 2px;
    border-radius: 0px;
    background: primary;
  }
</style>

<script>
  import config from '../config.js'
  import { common } from '../mixins/common'
  export default {
    mixins: [ common ],
    name: 'BaseNav',
    data () {
      return {
        pages: [],
        right: null,
        sheet: false,
        title: "Admin",
        icon: "fa-user-cog",
        path: "/",
        contentVersion: "n/a",
        mini: false,
        env: process.env.VUE_APP_ENVIRONMENT
      }
    },
    props: {
      softwareVersion: {
        type: String,
        default: 'n/a'
      }
    },
    mounted: function () {
      if (config['nav']) {
        if (config['nav'].title) {
          this.title = config['nav'].title
        }
        if (config['nav'].icon) {
          this.icon = config['nav'].icon
        }
        if (config['nav'].path) {
          this.path = config['nav'].path
        }
        if (config['nav'].version) {
          this.contentVersion = config['nav'].version
        }
      }
      for (var page in config) {
        if (Object.prototype.hasOwnProperty.call(config[page], 'nav') && config[page]['nav'] == true) {
          config[page]['title'] = this.capitalizeFirstLetter(page)
          config[page]['path'] = "/" + page
          if (!(Object.prototype.hasOwnProperty.call(config[page],'icon'))) {
            config[page]['icon'] = 'fa-cloud'
          }
          this.pages.push(config[page])
        }
      }
      this.pages.push({ title: 'Logout', icon: 'fa-sign-out-alt', path: 'logout' })
    },
    methods: {
      nav(path) {
        if (path == 'logout') {
          this.$auth.logout()
          this.$router.push({ path: '/login' })
        } else {
          this.$router.push({ path: path })
        }
      }
    }
  }
</script>
