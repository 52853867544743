import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-1 ma-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"grey lighten-3 px-2 py-1",attrs:{"outlined":"","tile":""}},[(_vm.bannerLabel)?_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.bannerLabel))]):_vm._e(),(_vm.bannerText)?_c('div',[(_vm.bannerIcon)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.showIcon(_vm.bannerIcon, _vm.bannerText))+" ")]):_vm._e(),_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.bannerText)+" ")])],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }