<template>
    <!-- BANNER/DISPLAY -->
    <v-container class="px-1 ma-0" fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="grey lighten-3 px-2 py-1" outlined tile>
                    <span v-if="bannerLabel" class="text-caption">{{ bannerLabel }}</span>
                    <div v-if="bannerText">
                        <v-icon v-if="bannerIcon">
                            {{ showIcon(bannerIcon, bannerText) }}
                        </v-icon>
                        <span class="text-subtitle-1">
                            {{ bannerText }}
                        </span>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { common } from '../mixins/common'
    export default {
    mixins: [ common ],
        props: {
            bannerIcon: {
                type: [ String, Number, Object ]
            },
            bannerLabel: {
                type: String
            },
            bannerText: {
                type: [ String, Number ]
            }
        }
    }
</script>