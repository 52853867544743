<template>
    <!-- CHECKLIST -->
    <v-container  class="pa-2 my-3" fluid>
        <div slot="label"> 
            {{ label }}
            <v-tooltip bottom v-if="tooltip">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">fa-question-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </div>
        <v-layout wrap>
            <v-flex xs12 sm4 md3 v-for="n in items" :key="n.value">
                <v-checkbox 
                    v-model="checkedBoxes" 
                    :label="n.name"
                    :value="n.value"
                ></v-checkbox>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                checkedBoxes: []
            }
        },
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            items: {
                type: Array
            },
            selected: {
                type: Array
            }
        },
        watch: {
            selected: {
                handler (val) {
                    this.checkedBoxes = val
                }
            },
            checkedBoxes: {
                handler (val) {
                    this.$emit('checked', val)
                }
            }
        }
    }
</script>