import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VBottomSheet,{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c(VCard,{staticClass:"text-center pa-4",attrs:{"color":"error lighten-2"}},[_c('h2',[_c(VIcon,{attrs:{"large":""}},[_vm._v("fa-exclamation-triangle")]),_vm._v("    "+_vm._s(_vm.errorCode)+":"+_vm._s(_vm.errorMessage)+"    "),_c(VBtn,{attrs:{"color":"error"},nativeOn:{"click":function($event){return _vm.moreInfo()}}},[_vm._v("more info")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }