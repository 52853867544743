<template>
    <v-container class="pa-2 my-1" fluid>
        <!-- TEXTAREA -->
        <v-textarea
            v-model="entered"
            :label="label"
            :rows=rows
            :value="defaultValue"
            :readonly="readonly"
        ></v-textarea>
    </v-container>
</template>

<script>
  export default {
    data: function () {
        return {
            entered: ''
        }
    },
    props: {
        label: {
            type: String
        },
        rows: {
            type: Number,
            default: 3
        },
        defaultValue: {
            type: String
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        // set the default value of text area
        defaultValue: {
            handler (val) {
                if (typeof val !== "undefined") {
                    this.entered = val
                }
            }
        },
        // send the entered value back to parent form
        entered: {
            handler (val) {
                this.$emit('valueEntered', val)
            }
        }
    }
}
</script>


