<template>
    <!-- SELECT -->
    <v-container  class="pa-2 my-3" fluid>
        <v-select dense
            :label="label"
            :items="items"
            :readonly="readonly"
            v-model="selected"
            item-text="name">
        </v-select>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                selected: {}
            }
        },
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            readonly: {
                type: Boolean
            },
            items: {
                type: Array
            },
            defaultValue: {
                type: [ Boolean, String, Number, Array ]
            }
        },
        watch: {
            defaultValue: {
                handler (val) {
                    this.selected = val
                }
            },
            selected: {
                handler (val) {
                    this.$emit('selected', val)
                }
            }
        }
    }
</script>