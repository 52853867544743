<template>
    <!-- CLICKABLE LIST -->
    <v-container class="pa-2 my-3" fluid>
        <div slot="label"> 
            {{ label }}
            <v-tooltip bottom v-if="tooltip">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">fa-question-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </div>
        <v-row>
            <v-col cols="12" sm="6" md="12">
                <v-list dense flat>
                    <v-list-item-group v-model="selected">
                        <v-list-item v-for="item in items" :key="item.name">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { common } from '../mixins/common'    
    export default {
        mixins: [ common ],
        data: () => ({
            selected: null
        }),
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            items: {
                type: Array
            }
        },
        watch: {
            selected: {
                handler (val) {
                    if (val != null) {
                        if (this.items[val].route) {
                            this.go(this.items[val].route)
                        } else if (this.items[val].name) {
                            this.go(this.items[val].name, 'filename.jpg')
                        }
                        this.selected = null
                    }
                }
            }
        }
    }
</script>
