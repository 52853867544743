import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_c(VIcon,{attrs:{"large":""}},[_vm._v("fa-cogs")]),_vm._v(" Chomp Admin Login")],1),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","large":"","target":"_blank"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"large":""}},[_vm._v("fa-question")])],1)]}}])},[_c('span',[_vm._v("Forgot Password?")])])],1),_c(VForm,[_c(VCardText,[_c(VTextField,{attrs:{"rules":_vm.emailRules,"prepend-icon":"fa-user","id":"email","name":"email","label":"Email","type":"text","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"prepend-icon":"fa-lock","id":"password","name":"password","label":"Password","type":"password","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},nativeOn:{"click":function($event){return _vm.login()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-sign-in-alt")]),_vm._v(" Login")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }