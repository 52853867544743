import Vue from 'vue'

import {HTTP_AUTH} from './http-common';

let auth = new Vue({

    data () {
        return { 
            refreshToken: null,
            accessToken: null,
            expiresAt: null,
            user: null
        }
    },

    methods: {
        login(email, password) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH({
                    data: {
                        grant_type: 'password',
                        client_id: process.env.VUE_APP_ADMIN_CLIENT_ID,
                        client_secret: process.env.VUE_APP_ADMIN_CLIENT_SECRET,
                        scope: 'read:auth create:auth update:auth delete:auth read:diary create:diary update:diary delete:diary read:venue create:venue update:venue delete:venue read:task create:task update:task delete:task read:readings read:supplier create:supplier update:supplier delete:supplier',
                        username: email,
                        password: password,
                    }
                })
                .then(response => {
                    this.setExpiresAt(response.data.expires_in)
                    this.setAccessToken(response.data.access_token)
                    this.setRefreshToken(response.data.refresh_token)
                    console.log("auth successful")
                })
                .catch(e => {
                    console.log("auth failed")
                    reject(e)
                })
                .finally(() => {
                    console.log("auth call completed")
                    resolve()
                })
            })
        },

        logout() {
            this.refreshToken = null
            this.accessToken = null
            this.expiresAt = null
            this.user = null
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('expires_at')
            localStorage.removeItem('user')
        },

        isAuthenticated() {
            return new Date().getTime() < this.getExpiresAt()
        },

        getRefreshToken: function() {
            return this.refreshToken || this.localStorage.getItem('refresh_token')
        },

        setRefreshToken: function(refresh_token) {
            this.refreshToken = refresh_token
            localStorage.setItem('refresh_token', refresh_token)
        },

        getAccessToken: function() {
            return this.accessToken || localStorage.getItem('access_token')
        },

        setAccessToken: function(accessToken) {
            this.accessToken = accessToken
            localStorage.setItem('access_token', accessToken)
        },

        getExpiresAt: function() {
            return this.expiredAt || localStorage.getItem('expires_at')
        },

        setExpiresAt: function(expiresIn) {
            let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
            this.expiresAt = expiresAt
            localStorage.setItem('expires_at', expiresAt)
        },

        getUser: function() {
            return this.user || JSON.parse(localStorage.getItem('user'))
        },

        setUser: function(user) {
            localStorage.setItem('user', JSON.stringify(user))
        }
    }
})

export default {
    install: function(Vue) {
        Vue.prototype.$auth = auth
    }
}