<template>
  <v-card>
    <br />
    <p v-html="data"></p>
    <br />
  </v-card>
</template>

<script>
  import { common, ajax } from '../mixins/common'
  export default {
    mixins: [ common ],
    data: () => ({
      response: {},
      data: ""
    }),
    props: {
      id: {
          type: String
      },
      page: {
          type: Object
      }
    },
    watch: {
      page: {
        handler() {
          this.getData()
        }
      },
      response: {
        handler(val) {
          Object.keys(val).forEach(this.replaceValueInContent)
        }
      }
    },
    mounted: function () {
      this.getData()
    },
    methods: {
      getData() {
        this.data = this.page['content']
        if (typeof this.page['url'] !== 'undefined') {
          ajax.get(this.url(this.page['url'], this.id), {
          })
          .then(response => {
            this.response = response.data
            this.$emit('data', this.response)
          })
          .catch(e => {
            this.$emit('error', e)
            this.loading = false;
          })
        }
      },
      replaceValueInContent(key) {
        var sRegExInput = new RegExp("\\{" + key + "\\}", "gi")
        this.data = this.content.replace(sRegExInput, this.response[key])
      }
    }
  }
</script>
