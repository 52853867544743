import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VCardText,[_c(VList,{attrs:{"two-line":""}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(_vm.showListItem(item, _vm.data))?[(item.header)?_c(VSubheader,{key:item.header,attrs:{"avatar":""}},[_vm._v(" "+_vm._s(item.header)+" ")]):_vm._e(),_c(VDivider,{key:index}),(!item.header)?_c(VListItem,{key:item.title,on:{"click":function($event){return _vm.listItemClicked(item)}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.subtitle))])],1)],1):_vm._e()]:_vm._e()],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }