import Vue from 'vue'
import Router from 'vue-router'
import Page from './views/PageView.vue'
import Login from "./views/LoginView.vue"

Vue.use(Router)

const routes = [
    {
      path: "/login",
      name: "login",
      component: Login
    }, 
    {
      path: '/',
      component: Page,
      props: true
    },
    {
      path: '/page/:page',
      component: Page,
      props: true
    },
    {
      path: '/page/:page/:id',
      component: Page,
      props: true
    },
    {
      path: '/:page',
      component: Page,
      props: true
    },
    {
      path: '/:page/:id',
      component: Page,
      props: true
    }
  ]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    // allow access to login page, regardless of state
    next()
  } else if (router.app.$auth.isAuthenticated()) { 
    // if authenticated allow access
    next()
  } else { 
    // send user to login
    router.push({ path: `/login` })
  }
})

export default router