<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title><v-icon large>fa-cogs</v-icon>&nbsp;Chomp Admin Login</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" 
                      v-on="on"
                      icon
                      large
                      target="_blank"
                    >
                      <v-icon large>fa-question</v-icon>
                    </v-btn>
                  </template>
                  <span>Forgot Password?</span>
                </v-tooltip>
              </v-toolbar>
              <v-form>
                <v-card-text>
                  <v-text-field v-on:keyup.enter="login()" v-model="email" :rules="emailRules" prepend-icon="fa-user" id="email" name="email" label="Email" type="text" required></v-text-field>
                  <v-text-field v-on:keyup.enter="login()" v-model="password" prepend-icon="fa-lock" id="password" name="password" label="Password" type="password" required></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!valid" v-on:click.native="login()" color="primary"><v-icon small>fa-sign-in-alt</v-icon>&nbsp;Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    data: () => ({
        valid: true,
        email: '',
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        password: ''
    }),
    name: 'LoginView',
    methods: {
      login() {
        this.$auth.login(this.email, this.password)
          .then(() => {
              this.$router.push({ path: '/'})
          })
          .catch(e => {
            this.$emit('error', e)
          })
      }
    }
  }
</script>

