<template>
    <v-app>
      <v-main>
        <v-container fluid>
          <v-flex xs12>
            <v-layout>
              <BaseNav :softwareVersion=softwareVersion v-show="$route.path==='/login' ? false : true"></BaseNav>
              <BaseError :error="appError" @dialog="dialog" v-show="$route.path==='/login' ? false : true"></BaseError>
              <BaseDialog 
                :showDialog="appDialogShow" 
                :title="appDialogTitle" 
                :message="appDialogMessage" 
                :close="appDialogClose"
                @show="show"
                v-show="$route.path==='/login' ? false : true">
              </BaseDialog>
              <router-view @error="error" @dialog="dialog" :key="$route.fullPath"></router-view>
            </v-layout>
          </v-flex>
        </v-container>
      </v-main>
    </v-app>
</template>

<script>
  export default {
    data () {
      return {
        appError: new Error(),
        softwareVersion: process.env.VUE_APP_VERSION,
        appDialogShow: false,
        appDialogTitle: 'Test',
        appDialogMessage: 'Are you sure you want to do this thing?',
        appDialogClose: false
      }
    },
    methods: {
      error(e) {
        this.appError = e
      },
      dialog(title, message, close) {
        this.appDialogShow = Math.random()
        this.appDialogTitle = title
        this.appDialogMessage = message
        this.appDialogClose = close
      },
      show(value) {
        this.appDialogShow = value
      }
    }
  }
</script>
