import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.showDialog,"transition":"dialog-top-transition","hide-overlay":"","persistent":"","width":"600","scrollable":"","max-width":"600"}},[_c(VCard,[(_vm.title)?_c(VCardTitle,{staticClass:"text-h5 grey"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.dialogMessage)?_c(VCardText,[_c('div',{staticClass:"text-center text-h5 pa-8"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dialogMessage)}})])]):_vm._e(),(_vm.dialogObject)?_c(VCardText,{staticStyle:{"height":"400px"}},[_c('vue-json-pretty',{attrs:{"data":_vm.dialogObject}})],1):_vm._e(),_c(VDivider),(_vm.autoClose)?_c(VProgressLinear,{staticClass:"mb-0",attrs:{"buffer-value":_vm.progress,"indeterminate":_vm.indeterminate,"reverse":"","stream":"","height":"20"}}):_vm._e(),(_vm.choice)?_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog(true)}}},[_vm._v(" OK ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog(false)}}},[_vm._v(" Cancel ")])],1):_vm._e(),(_vm.confirm)?_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog(false)}}},[_vm._v(" Close ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }