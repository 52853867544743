import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VLayout,[_c('BaseNav',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path==='/login' ? false : true),expression:"$route.path==='/login' ? false : true"}],attrs:{"softwareVersion":_vm.softwareVersion}}),_c('BaseError',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path==='/login' ? false : true),expression:"$route.path==='/login' ? false : true"}],attrs:{"error":_vm.appError},on:{"dialog":_vm.dialog}}),_c('BaseDialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path==='/login' ? false : true),expression:"$route.path==='/login' ? false : true"}],attrs:{"showDialog":_vm.appDialogShow,"title":_vm.appDialogTitle,"message":_vm.appDialogMessage,"close":_vm.appDialogClose},on:{"show":_vm.show}}),_c('router-view',{key:_vm.$route.fullPath,on:{"error":_vm.error,"dialog":_vm.dialog}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }