<template>
    <v-container fluid class="py-0">
        <BaseToolBar 
            :icon="pages[page].icon" 
            :title="pageTitle"
            :help="pages[page].help"
            :dataUrls="pageUrls()"
            :data="someData"
            :jwtToken="jwtToken">
        </BaseToolBar>
        <BaseSubtitle v-if="pages[page]['subtitle']"
            :id="id"
            :subtitle="pages[page]['subtitle']"
            @error="error">
        </BaseSubtitle>
        <BaseList 
            v-if="pages[page]['list']"
            :id="id"
            :page="page"
            :list="pages[page]['list']"
            @data="setSomeData"
            @dialog="dialog"
            @error="error"
        ></BaseList>
        <BaseTablePage
            v-else-if="pages[page]['table']"
            :id="id"
            :title="pageTitle"
            :table="pages[page]['table']"
            @data="setSomeData"
            @dialog="dialog"
            @error="error"
        ></BaseTablePage>
        <BaseForm 
            v-else-if="pages[page]['form']"
            :id="id"
            :form="pages[page]['form']"
            @data="setSomeData"
            @dialog="dialog"
            @addOrModify="setAddOrModify"
            @error="error"
        ></BaseForm>
        <BaseGraph 
            v-else-if="pages[page]['graph']"
            :id="id"
            :title="pageTitle"
            :graph="pages[page]['graph']"
            @data="setSomeData"
            @dialog="dialog"
            @error="error"
        ></BaseGraph>
        <BasePage 
            v-else
            :id="id"
            :page="pages[page]['page']"
            @data="setSomeData"
            @error="error"
        ></BasePage>
    </v-container>
</template>

<script>
    import config from '../config.js'
    import { common } from '../mixins/common'
    export default {
        mixins: [ common ],
        data () {
            return {
                pages: config,
                someData: {},
                addOrModify: ''
           }
        },
        props: {
            page: {
                type: String,
                default: function () {
                    for (var a_page in config) {
                        if (typeof config[a_page].home !== 'undefined' && config[a_page].home == true) {
                            return a_page
                        }
                    }
                }
            },
            id: {
                type: String
            }
        },
        watch: {
            '$route' (to, from) {
                this.from = from
            }
        },
        computed: {
            pageTitle: function() {
                let titlePrePend = ''
                if (this.pages[this.page]['form']) {
                    titlePrePend = this.addOrModify
                }
                if (typeof this.pages[this.page].title !== "undefined") {
                    return this.capitalizeFirstLetter(titlePrePend + this.pages[this.page].title)
                } else {
                    return this.capitalizeFirstLetter(titlePrePend + this.page)
                }
            }
        },
        methods: {
            dialog(m, t, c) {
                this.$emit('dialog', m, t, c)
            },
            setAddOrModify(value) {
                this.addOrModify = value + " "
            },
            pageUrls() {
                var urls = [];
                if (typeof this.pages[this.page].table !== "undefined") {
                    urls.push(this.pages[this.page].table['url'])
                }
                if (typeof this.pages[this.page].form !== "undefined") {
                    urls.push(this.pages[this.page].form['url'])
                }
                if (typeof this.pages[this.page].page !== "undefined") {
                    urls.push(this.pages[this.page].page['url'])
                }
                if (typeof this.pages[this.page].graph !== "undefined") {
                    for (var item in this.pages[this.page].graph['data']) {
                        urls.push(this.pages[this.page].graph['data'][item].url)
                    }
                }
                return urls
            },
            setSomeData(data) {
                this.someData = data
            }
        },
        created() {
            if (typeof this.pages[this.page] === 'undefined') {
                this.error(new Error("Page '" + this.page + "' does not exist.  Please check your configuration."))
            }
        }
    }
</script>