import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-2 my-2",attrs:{"fluid":""}},[_c(VAutocomplete,{attrs:{"items":_vm.items,"item-text":_vm.formItem.autocomplete.text,"item-value":_vm.formItem.autocomplete.value,"label":_vm.formItem.label,"readonly":_vm.formItem.readonly || _vm.loading,"search-input":_vm.search,"value":_vm.selected,"loading":_vm.loading,"cache-items":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(!_vm.formItem.autocomplete.icon)?_c(VListItemAvatar,{staticClass:"text-h9 font-weight-light white--text",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item[_vm.formItem.autocomplete.text].substring(0, 3))+" ")]):_vm._e(),(_vm.formItem.autocomplete.icon)?_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.formItem.autocomplete.icon))])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item[_vm.formItem.autocomplete.text]))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item[_vm.formItem.autocomplete.value]))])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }