<template>
    <!-- TABLE -->
    <v-card class="pa-2 my-3" fluid>
        <v-card-title>{{ label }}</v-card-title>
        <v-card-text>
            <v-data-table
                :headers="tableHeaders"
                :items="items"
                :no-data-text="noDataText"
                :loading="loading"
                :items-per-page="pagination.pageSize"
                :sort-by.sync="pagination.sortBy"
                :sort-desc.sync="pagination.sortDesc"
                fixed-header
                hide-default-footer
                loading-text="Loading... Please wait"
                class="elevation-1">
                <template slot="progress">
                    <v-progress-linear color="primary" height="15" indeterminate striped></v-progress-linear>
                </template>
                <template slot="item" slot-scope="props">
                    <tr>
                        <td v-for="(header, index) in tableHeaders" :key="index">
                            <span v-if="header.route">
                                <a @click="recent(props.item);followRoute(header, props.item)">
                                    <span v-if="header.icon">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="primary--text text--lighten-2 custom-icon">{{ display(header, props.item) }}</v-icon>
                                            </template>
                                            <span>{{ display(header, props.item, 0) }}</span>
                                        </v-tooltip>
                                    </span>
                                    <span v-else>{{ display(header, props.item) }}</span>
                                </a>
                            </span>
                            <span v-else-if="header.delete">
                                <v-icon v-if="header.icon" @click="dialog(header.delete, props.item[header.value], 'delete')" class="primary--text text--lighten-2 custom-icon">{{ header.icon }}</v-icon>
                                <span v-else>{{ display(header, props.item) }}</span>
                            </span>
                            <span v-else-if="header.update">
                                <v-icon v-if="header.icon" @click="dialog(header.update, props.item[header.value], 'update')" class="primary--text text--lighten-2 custom-icon">{{ header.icon }}</v-icon>
                                <span v-else>{{ display(header, props.item) }}</span>
                            </span>
                            <span v-else>
                                <span v-if="header.icon">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on">{{ display(header, props.item) }}</v-icon>
                                        </template>
                                        <span>{{ display(header, props.item, 0) }}</span>
                                    </v-tooltip>
                                </span>
                                <span v-else>{{ display(header, props.item) }}</span>
                            </span>
                        </td>
                    </tr>
                </template>
                <template slot="no-data">
                    <v-alert class="ma-4" :value="true" type="info" color="primary" icon="fa-info-circle" outlined>{{ noDataText }}</v-alert>
                </template>
                <template slot="top" v-if="controls">
                    <v-container fluid>
                        <v-row align="center" no-gutters>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="pagination.search"
                                    append-icon="fa-search"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-pagination
                                    @input="changePage"
                                    :value="pagination.page"
                                    :length="pages"
                                    total-visible=11
                                    circle
                                ></v-pagination>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <v-menu 
                                    close-on-click
                                    close-on-content-click
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ itemsPerPageMenu[selectedItemsPerPageIndex] }} Per Page
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item-group mandatory v-model="selectedItemsPerPageIndex">
                                            <v-list-item
                                                v-for="(item, index) in itemsPerPageMenu"
                                                :key="index">
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col cols="1" class="text-center">
                                <v-chip
                                    class="ma-2"
                                    label
                                    outlined
                                    color="primary"
                                    >
                                    {{ total.toLocaleString() }} Rows
                                    </v-chip>
                            </v-col>
                            <v-col cols="2" class="text-right"> 
                                <v-btn v-if="showCachedDataButton" v-on:click.native="onRefreshCache()"><v-icon size="small">fa-redo</v-icon>&nbsp;Refresh</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-text v-if="addButtonUrl" style="height: 50px; position: relative;">
            <v-fab-transition>
            <v-btn
                v-show="addButtonUrl"
                v-on:click.native="go(addButtonUrl)"
                color="primary"
                dark
                absolute
                top
                left
                fab>
                <v-icon>fa-plus-circle</v-icon>
            </v-btn>
            </v-fab-transition>
        </v-card-text>
    </v-card>
</template>

<script>
    import { common, ajax } from '../mixins/common'
    export default {
        mixins: [ common ],
        data: function () {
            return {
                items: [],
                loading: true,
                noDataText: 'No data available',
                itemsPerPageMenu: [ 5, 10, 25, 50, 'All' ],
                selectedItemsPerPageIndex: 1,
                pagination: {
                    page: 1,
                    pageSize: 10,
                    sortBy: '',
                    sortDesc: false,
                    search: ''
                }
            }
        },
        methods: {
            onRefreshCache() {
                this.$emit('onRefreshCache');
            },
            recent(item) {
                var uniqueKeyObj = this.tableHeaders.find(obj => {
                    return obj.uniqueKey === true
                })
                let uniqueKey;
                if (uniqueKeyObj) {
                    uniqueKey = uniqueKeyObj.value
                }
                this.storeRecent(item, this.saveToRecent, uniqueKey)
            },
            followRoute(header, item) {
                if (header.id) {
                    this.go(this.url(header.route, item[header.id]))
                } else if (header.key) {
                    this.go(this.url(header.route, item[header.value][header.key]))
                } else {
                    this.go(this.url(header.route, item))
                }
            },
            changePage: function (event) {
                this.loading = true
                if (event) {
                    this.pagination.page = event
                    this.changePagination()
                }
            },
            changePagination: function() {
                this.loading = true
                this.$emit('pagination', this.pagination)
            },
            dialog(url, data, action) {
                var func
                if (action == 'delete') {
                    func = this.del
                } else {
                    func = this.update
                }
                this.$emit('dialog', action + ' Item', 'Are you sure you would like to ' + action + ' this item?', func.bind(this, url, data))
            },
            del(url, data) {
                ajax.delete(this.url(url, data))
                    .then(response => {
                    console.log(response.data)
                    this.$emit('dialog', 'Deleted!', 'Deleted!', true)
                })
                    .catch(e => {
                    this.$emit('error', e)
                })
            },
            update(url, data) {
                ajax.patch(this.url(url, data))
                    .then(response => {
                    console.log(response.data)
                    this.$emit('dialog', 'Updated!', 'Updated!', true)
                })
                    .catch(e => {
                    this.$emit('error', e)
                })
            }
        },
        computed: {
            pages: function() {
                if (this.total && this.pagination.pageSize) {
                    return Math.ceil(this.total/this.pagination.pageSize)
                } else if (this.items.length) {
                    return Math.ceil(this.items.length/this.pagination.pageSize)
                } else {
                    return 1
                }
            },
            tableHeaders: function() {
                var val = this.headers
                if (this.deleteButtonUrl) {
                    val.push({ 
                        'text': 'Delete', 
                        'value': 'id', 
                        'icon': 'fa-trash', 
                        'delete': this.deleteButtonUrl
                    })
                }
                return val
            }
        },
        props: {
            label: {
                type: String
            },
            headers: {
                type: Array
            },
            rows: {
                type: [ Array, Number, Object, String ]
            },
            total: {
                type: Number
            },
            addButtonUrl: {
                type: String
            },
            deleteButtonUrl: {
                type: String
            },
            controls: {
                type: Boolean,
                default: true
            },
            saveToRecent: {
                type: String
            },
            showCachedDataButton: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            'pagination.sortBy': {
                handler () {
                    this.changePagination()
                }
            },
            'pagination.sortDesc': {
                handler () {
                    this.changePagination()
                }
            },
            'pagination.search': {
                handler () {
                    this.changePagination()
                }
            },
            selectedItemsPerPageIndex: {
                handler (val) {
                    if (typeof this.itemsPerPageMenu[val] == "string") {
                        this.pagination.pageSize = -1
                    } else if (typeof val != "undefined") {
                        this.pagination.pageSize = this.itemsPerPageMenu[val]
                    }
                    // if our page number is past our total pages
                    if (this.pagination.page > this.pages) {
                        this.pagination.page = this.pages
                    }
                    this.changePagination()
                }
            },
            rows: {
                handler (val) {
                    if (typeof val !== "undefined" && val !== null) {
                        if (typeof val === "object") {
                            if (!this.controls) {
                                // without controls we need one long table
                                this.pagination.pageSize = this.rows.length
                            }
                            // if our page number is past our total pages
                            if (this.pagination.page > this.pages) {
                                this.pagination.page = this.pages
                            }
                            this.items = val
                        }
                        this.loading = false    
                    } else {
                        this.loading = true
                        this.items = []
                    }
                }
            }
        }
    }
</script>

<style scoped>
.custom-icon:hover {
  opacity: 0.5;
}
</style>