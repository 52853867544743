<template>
    <v-container class="pa-2 my-1" fluid>
        <!-- TEXTFIELD / PASSWORD -->
        <v-text-field
            v-model="entered"
            :type="formType"
            :label="label"
            :readonly="readonly"
            :filled="readonly"
            :append-icon="append_icon"
            @click:append="show_password = !show_password"
        ></v-text-field>
    </v-container>
</template>

<script>
  export default {
    data: function () {
        return {
            entered: '',
            show_password: true,
            append_icon: '',
            formType: 'text'
        }
    },
    props: {
        label: {
            type: String
        },
        defaultValue: {
            type: [ String, Number ]
        },
        readonly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        }
    },
    watch: {
        // set the default value of text field
        defaultValue: {
            handler (val) {
                if (typeof val !== "undefined") {
                    this.entered = val
                }
            }
        },
        // if show password changes, change icon
        show_password: {
            handler (val) {
                if (val) {
                    this.append_icon = 'fa-eye'
                    this.formType = "text"
                } else {
                    this.append_icon = 'fa-eye-slash'
                    this.formType = "password"
                }
            }
        },
        // send the entered value back to parent form
        entered: {
            handler (val) {
                this.$emit('valueEntered', val)
            }
        }
    },
    mounted() {
        if (this.type == "password") {
            this.formType = "password"
            this.show_password = false
        }
    }
}
</script>


