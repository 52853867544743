import { render, staticRenderFns } from "./BaseNav.vue?vue&type=template&id=20a9b018&scoped=true"
import script from "./BaseNav.vue?vue&type=script&lang=js"
export * from "./BaseNav.vue?vue&type=script&lang=js"
import style0 from "./BaseNav.vue?vue&type=style&index=0&id=20a9b018&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a9b018",
  null
  
)

export default component.exports