import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-2 my-3",attrs:{"fluid":""}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltip)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("fa-question-circle")])]}}],null,false,3226189860)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"12"}},[_c(VList,{attrs:{"dense":"","flat":""}},[_c(VListItemGroup,{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.name},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }