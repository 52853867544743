<template>
  <v-card scrollable>
    <v-progress-linear color="primary" height="15" :indeterminate="loading" :active="loading"></v-progress-linear>
    <v-card-text>
      <v-flex>
        <div v-for="(formItem, formKey) in formDesign" :key="formItem.label">
          <template v-if="showFormComponentAndSetDefault(formItem)">

            <!-- TEXTFIELD / PASSWORD -->
            <BaseTextField v-if="formItem.type == 'text' || formItem.type == 'password' || !formItem.type"
              :label="formItem.label"
              :defaultValue="data[formItem.name]"
              :readonly="formItem.readonly"
              :type="formItem.type"
              @valueEntered="data[formItem.name] = $event"
            ></BaseTextField>

            <!-- TEXTAREA -->
            <BaseTextArea v-if="formItem.type == 'textarea'"
              :label="formItem.label"
              :rows="formItem.size"
              :defaultValue="data[formItem.name]"
              :readonly="formItem.readonly"
              @valueEntered="data[formItem.name] = $event"
            ></BaseTextArea>

            <!-- DATETIME -->
            <BaseTextField v-if="formItem.type == 'datetime'"
              :label="formItem.label"
              :defaultValue="data[formItem.name]"
              :readonly="true"
            ></BaseTextField>

            <!-- BANNER -->
            <BaseBanner v-if="formItem.type == 'banner'"
              :bannerIcon="formItem.icon"
              :bannerLabel="formItem.label"
              :bannerText="data[formItem.name]"
              v-model="data[formItem.value]"
            ></BaseBanner>

            <!-- AUTOCOMPLETE -->
            <BaseAutoComplete v-if="formItem.type == 'autocomplete'"
              :formItem="formItem"
              :formUrl="urls[formItem.name]"
              :defaultValue="data[formItem.name]"
              @formSelection="data[formItem.name] = $event"
              @error="error"
            ></BaseAutoComplete>

            <!-- JSON -->
            <BaseRenderJson v-if="formItem.type == 'json'"
              :label="formItem.label"
              :jsonObject="data[formItem.name]"
            ></BaseRenderJson>

            <!-- CLICKABLE LIST -->
            <BaseClickList v-if="formItem.type == 'clicklist'"
              :label="formItem.label"
              :tooltip="formItem.tooltip"
              :items="formItem.list"
            ></BaseClickList>

            <!-- CHECKLIST -->
            <BaseCheckList v-if="formItem.type == 'checklist'"
              :label="formItem.label"
              :tooltip="formItem.tooltip"
              :readonly="formItem.readonly"
              :items="formItem.list"
              :selected="checkboxes[formItem.name]"
              @checked="data[formItem.name] = $event"
            ></BaseCheckList>

            <!-- RADIO BUTTONS -->
            <BaseRadioButtons v-if="formItem.type == 'list'"
              :label="formItem.label"
              :tooltip="formItem.tooltip"
              :readonly="formItem.readonly"
              :items="formItem.list"
              :defaultValue="data[formItem.name]"
              @checked="data[formItem.name] = $event"
            ></BaseRadioButtons>

            <!-- SELECT LIST -->
            <BaseSelect v-if="formItem.type == 'select'"
              :label="formItem.label"
              :tooltip="formItem.tooltip"
              :readonly="formItem.readonly"
              :items="formItem.list"
              :defaultValue="data[formItem.name]"
              @selected="data[formItem.name] = $event"
            ></BaseSelect>

            <!-- TOGGLE -->
            <BaseToggle v-if="formItem.type == 'toggle'"
              :label="formItem.label"
              :tooltip="formItem.tooltip"
              :readonly="formItem.readonly"
              :onValue="formItem.onValue"
              :offValue="formItem.offValue"
              :defaultValue="data[formItem.name]"
              @checked="data[formItem.name] = $event">
            </BaseToggle>

            <!-- BUTTON -->
            <BaseButton v-if="formItem.type == 'button'"
              :label="formItem.label"
              :icon="formItem.icon"
              :readonly="formItem.readonly"
              :loading="buttonPushed[formKey]"
              @clicked="buttonPush(formKey, formItem, data[formItem.name])">
            </BaseButton>

            <!-- TABLE -->
            <BaseTable v-if="formItem.type == 'table'"
              :label="formItem.label"
              :headers="formItem.headers"
              :rows="data[formItem.name]"
              :addButtonUrl="url(formItem.add, data, { 'add': true })"
              :deleteButtonUrl="formItem.delete"
              :controls=false
              @dialog="dialog"
              @error="error"
            ></BaseTable>
            
            <!-- NOTE -->
            <BaseNote v-if="formItem.type == 'note'"
              :label="formItem.label"
              :message="data[formItem.name]"
              :type="formItem.level"
            >
            </BaseNote>

            <!-- LINK -->
            <v-container v-if="formItem.type == 'link' && formItem.route" class="pa-2 my-3" fluid>
              <div>
                <a v-on:click="go(url(formItem.route, data[formItem.name]))">
                  <span>
                    <v-icon v-if="formItem.icon" class="pr-3" > {{ formItem.icon }} </v-icon> {{ formItem.label }}
                  </span>
                </a>
              </div>
            </v-container> 

            <!-- QRCODE -->
            <v-container v-if="formItem.type == 'qrcode'" class="pa-ma-0" fluid>
              <div class="text-h6 mb-1">{{ formItem.label }}</div>               
              <v-img 
                :key="makeImageShow"
                :src="data[formItem.name]" 
                max-width="320"
                max-height="320" 
                :alt="formItem.label">
              </v-img>
            </v-container> 

            <!-- DOWNLOAD -->
            <v-container v-if="formItem.type == 'download'" class="pa-ma-0" fluid>
              <a v-on:click="go(url(formItem.route, data[formItem.name]), formItem.filename)">
                <span>
                  <v-icon v-if="formItem.icon"> {{ formItem.icon }} </v-icon> {{ formItem.label }}
                </span>
              </a>
            </v-container> 

            <!-- DATE -->
            <v-menu v-if="formItem.type == 'date'"
              v-model="datePicker[formKey]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-icon="fa-calendar"
                  :label="formItem.label"
                  :value="convert(data[formItem.name], 'date')"
                  :readonly="formItem.readonly"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data[formItem.name]"
                @input="datePicker[formKey] = false"
                :readonly="formItem.readonly"
                :max="setDatePicker('max', formItem)"
                :min="setDatePicker('min', formItem)"
              ></v-date-picker>
            </v-menu>

            <!-- TIME -->
            <v-container v-if="formItem.type == 'time'" class="pa-2 my-3" fluid>
              <div class="text-h6 mb-1">{{ formItem.label }}</div>
              <v-time-picker
                v-model="data[formItem.name]"
                class="mt-4"
                format="24hr"
              ></v-time-picker>
            </v-container>

            <!-- SWITCH ON/OFF -->
            <v-container v-if="formItem.type == 'switch'" class="pa-2 my-3" fluid>
              <v-switch
                v-model="data[formItem.name]"
                :label="formItem.label"
              ></v-switch>
            </v-container>

            <!-- Output Debug Info, Useful when debugging or adding new form components -->
            <div v-if="formItem.debug">
              {{ formItem.name }}({{ formItem.value }}): {{ data[formItem.name] }}
            </div>
          </template>
        </div>
      </v-flex>
    </v-card-text>
    <v-card-actions v-if="!form.noactions" class="fab-container">
      <v-spacer></v-spacer>
      <v-btn :disabled="!valid" v-on:click.native="save(addForm)" color="primary" v-if="!addForm">Save</v-btn>
      <v-btn :disabled="!valid" v-on:click.native="save(addForm)" color="primary" v-if="addForm">Add</v-btn>
      <v-btn :disabled="!valid" v-on:click.native="cancel()" color="primary">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style>
  .fab-container {
    position: fixed;
    bottom: 50px;
    right: 60px;
    z-index: 2;
  }
</style>

<script>
  import { common, ajax } from '../mixins/common'
  export default {
    mixins: [ common ],
    data () {
      return {
        data: {},
        lookup: {},
        urls: [],
        addForm: false,
        loading: true,
        datePicker: [],
        checkboxes: {},
        buttonPushed: [],
        makeImageShow: ""
      }
    },
    props: {
      form: {
        type: Object
      }
    },
    computed: {
      valid: function () {
        if (typeof this.form.buttons !== "undefined") {
          if (this.form.buttons === false) {
            return false
          }
        }
        return true
      },
      pageUrl: function () {
        return this.url(this.form.url, this.id)
      },
      formDesign: function() {
        return JSON.parse(JSON.stringify(this.form.design))
      },
    },
    watch: {
      '$route': {
        handler () {
          this.loadPage()
        },
        immediate: true
      }
    },
    methods: {
      getId(formItem) {
        if (typeof this.data[formItem.id] !== 'object' && this.data[formItem.id]) {
          return this.data[formItem.id];
        } else if (typeof this.data[formItem.value] !== 'object' && this.data[formItem.value]) {
          return this.data[formItem.value];
        } else {
          return this.id
        }
      },
      loadPage() {
        if (this.$route.query.add == "true") {
          this.addForm = true
          this.$emit('addOrModify', 'add')
        } else {
          this.addForm = false
          this.$emit('addOrModify', 'modify')
        }
        this.checkUrlParameters()
        if (!this.addForm) {
          //
          // EDIT FORM
          //
          this.getFormData()

        } else {
            //
          // NEW FORM
          //
          this.setupNewForm()
        }
      },
      getFormData() {
        // Form Data
        if (typeof this.form.url !== 'undefined') {
          ajax.get(this.url(this.form.url, this.id), {})
            .then(response => {
              // reassign properties to the primary data object from the 
              // reponse in order to maintain reactivity
              for (var property in response.data) {
                this.$set(this.data, property, response.data[property])
              }
              // set up the form with any modifications to the data that is required
              this.setupModifyForm()
          })
            .catch(e => {
              this.error(e)
          })
        }
      },
      async setupModifyForm() {
        let index = 0
        for (var property in this.formDesign) {
          let formItem = this.formDesign[property]
          // if we don't have a name from the schema
          // we will use the value as a name, but
          // this is dangerous if the value is not
          // unique
          if (typeof formItem.name === "undefined") {
            formItem.name = formItem.value
          }
          this.$set(this.data, formItem.name, this.convert(this.data[formItem.value]))
          //
          // if our data value is actually an object, get the specified value 
          // of the object as our data value using the (hopefully) provided key
          // or alternatively the id
          //
          if (typeof this.data[formItem.value] === 'object' && this.data[formItem.value] !== null) {
            if (typeof formItem.key !== "undefined") {
              this.$set(this.data, formItem.name, this.convert(this.data[formItem.value][formItem.key]))
              //if (formItem.type != "list") {
                // if we are not populating a list
                
                //this.formDesign[property].name = formItem.key
              //} else {
                //this.$set(this.data, formItem.name, this.convert(this.data[formItem.value][formItem.key]))
              //} 
            } else if (typeof this.data[formItem.value].id !== "undefined") {
              this.$set(this.data, formItem.name, this.convert(this.data[formItem.value].id))
            }
          }
          //
          // autocomplete url
          //
          if (typeof formItem.autocomplete !== "undefined" && typeof formItem.autocomplete.url !== "undefined") {
            this.setAutoCompleteURL(formItem)
          }
          //
          // lookup the values from another resource
          //
          if (typeof formItem.lookup !== "undefined") {
            //
            // lookup values from another resource as long as the value has been provided
            //
            await this.setUpLookup(formItem)
          } else {
            this.$set(this.data, formItem.name, this.convert(this.data[formItem.name], formItem.convert))
          }
          //
          // checklists need special data formatting behavior
          //
          if (formItem.type == 'checklist') {
            if (this.data[formItem.name]) {
              this.$set(this.checkboxes, formItem.name, this.data[formItem.name].map(function(element) {
                if (typeof element !== "object") {
                  return element
                } else {
                  return element.id ? element.id : element.value
                }
              }));
            } else {
              this.$set(this.checkboxes, formItem.name, [])
            }
            if (formItem.lookup) {
              this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
            }
          }
          //
          // lists can be from the form design or built from service data
          //
          if (formItem.type == 'list' || formItem.type == 'clicklist') {
            if (typeof formItem.list == "undefined") {
              this.$set(this.formDesign[property], 'list', new Array())
              // populate the formItem.list as best we can from the data
              if (typeof this.data[formItem.name] == "object") {
                for (var item in this.data[formItem.name]) {
                  const newListItem = {}
                  // determine name if list item
                  if (typeof formItem.key !== "undefined") {
                    newListItem.name = this.data[formItem.name][item][formItem.key]
                  } else if (typeof this.data[formItem.name][item].name !== "undefined") {
                    newListItem.name = this.data[formItem.name][item].name
                  } else {
                    if (typeof item != "object") {
                      newListItem.name = String(this.data[formItem.name][item])
                    } else {
                      newListItem.name = String(this.data[formItem.name][item].id)
                    }
                  }
                  // set icon if neccesary
                  if (this.data[formItem.name][item].icon) {
                    newListItem.icon = this.data[formItem.name][item].icon
                  } else if (formItem.icon) {
                    newListItem.icon = formItem.icon
                  }
                  // set the path if neccesary
                  if (formItem.route) {
                    newListItem.route = this.url(formItem.route, this.data[formItem.name][item])
                  }
                  this.formDesign[property].list.push(newListItem)
                }
              }
            }
          }
          //
          // selects can use a range parameter to generate the values in the list
          //
          if (formItem.type == 'select') {
            if (typeof formItem.range == "object") {
              var min = 0
              if (typeof formItem.range.min == "number") {
                min = formItem.range.min
              }
              var max = 0
              if (typeof formItem.range.max == "number") {
                max = formItem.range.max
              }                     
              var step = 1
              if (typeof formItem.range.step == "number") {
                step = formItem.range.step
              }
              this.formDesign[property].list = []
              for (let current = min; current <= max; current = current + step) {
                const newListItem = {}
                newListItem.name = current
                newListItem.value = current
                this.formDesign[property].list.push(newListItem)
              }
            } else if (typeof formItem.list == "undefined") {
              if (formItem.lookup) {
                this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
              }
            }
          }
          //
          // replace with lookup data when neccesary
          //
          if (formItem.type == 'banner' || formItem.type == 'json' || formItem.type == 'table') {
            if (formItem.lookup) {
              this.$set(this.data, formItem.name, this.lookup[formItem.name])
            }
          }
          //
          // attach an index to buttons
          //
          if (formItem.type == 'button') {
            this.$set(this.buttonPushed, index, false)
          }
          //
          // datetimes should be formatted accordingly
          //
          if (formItem.type == 'datetime') {
            this.$set(this.data, formItem.name, this.convert(this.data[formItem.name], 'datetime'))
          }
          //
          // load image data
          //
          if (formItem.type == 'qrcode') {
            formItem.payload = this.replaceParams(formItem.payload, this.data)
            this.getImage(formItem.name, formItem.value, formItem.payload, this.setImage)
          }
          index++
        }
        this.$emit('data', this.data)
        this.loading = false
      },
      async setupNewForm() {
        //
        // NEW FORM
        //
        for (var property in this.formDesign) {
          let formItem = this.formDesign[property]
          // skip auto-complete and data lookups on form items that are not to be used
          // on add forms
          if (!formItem.noadd) {
            // name if it has been provided
            if (typeof formItem.name === "undefined") {
              formItem.name = formItem.value
            }
            this.$set(this.data, formItem.name, this.data[formItem.value])
            //
            // lookup values from another resource as long as the value has been provided
            //
            if (typeof formItem.lookup !== "undefined") {
              await this.setUpLookup(formItem)
              if (formItem.type == 'select' && typeof formItem.list == "undefined") {
                this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
              }
            }
            //
            // checklists should default to empty
            //
            if (formItem.type == 'checklist') {
              this.$set(this.checkboxes, formItem.name, [])
              if (typeof formItem.list == "undefined") {
                if (formItem.lookup) {
                  this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
                }
              }
            }
            //
            // autocomplete url
            //
            if (typeof formItem.autocomplete !== "undefined" && typeof formItem.autocomplete.url !== "undefined") {
              this.setAutoCompleteURL(formItem)
            }
            //
            // populate ranges
            //                
            if (typeof formItem.range !== "undefined") {
              var min = 0
              if (typeof formItem.range.min == "number") {
                min = formItem.range.min
              }
              var max = 0
              if (typeof formItem.range.max == "number") {
                max = formItem.range.max
              }                     
              var step = 1
              if (typeof formItem.range.step == "number") {
                step = formItem.range.step
              }
              formItem.list = []
              for (let current = min; current <= max; current = current + step) {
                const newListItem = {}
                newListItem.name = current
                newListItem.value = current
                formItem.list.push(newListItem)
              }
            }
            // set defaults
            if (typeof formItem.default !== "undefined" && typeof formItem.default !== "object") {
              if (typeof formItem.list !== "undefined") {
                for (var item in formItem.list) {
                  if (formItem.list[item].value == formItem.default) {
                    if (formItem.key) {
                      this.$set(this.data, formItem.key, formItem.list[item].value)
                      this.formDesign[property].value = formItem.key
                    }
                    this.$set(this.data, formItem.name, formItem.list[item].value)
                  }
                }
              } else {
                this.$set(this.data, formItem.name, formItem.default)
              }
            }
          }
        }
        this.checkUrlParameters()
        this.loading = false
      },
      //
      // takes a form item configuration and populates data using the lookup responses
      //
      async setUpLookup(config) {
        var lookupItems = []
        if (config.lookup.constructor !== Array) {
          lookupItems.push(config.lookup)
        } else {
          lookupItems = config.lookup
        }
        var promises = new Array()
        for (var index in lookupItems) {
          if (typeof lookupItems[index].url !== "undefined") {
            let lkUrl = ""
            if (typeof config.id !== "undefined") {
              lkUrl = this.url(lookupItems[index].url, this.data[config.id])
            } else if (typeof this.data[config.name] !== "undefined" && typeof this.data[config.name] !== 'object') {
              lkUrl = this.url(lookupItems[index].url, this.data[config.name])
            } else {
             lkUrl = this.url(lookupItems[index].url)
            }
            promises.push(this.lookupData(lkUrl, lookupItems[index].return, config.convert))
          }
        }
        return Promise.all(promises).then((values) => {
          var responses = values.shift()
          while (values.length > 0) {
            let a_response = values.shift()
            if (a_response.length > 0) {
              responses = responses.concat(a_response)
            }
          }
          //this.$set(this.data, config.name, responses)
          this.$set(this.lookup, config.name, responses)
        });
      },
      //
      // give a url, a returm schema, conversion rule
      // return lookup data
      //
      async lookupData(url, schema, convert) {
        return ajax.get(url, {})
          .then(response => {
            if (response.data.length && response.data.length > 0) {
              // look up response is an array or object and not empty
              let lookupArray = []
              for (let index in response.data) {
                if (typeof schema == 'object') {
                  // build a lookup response object from provided schema
                  var lookupResponse = {}
                  for (let key in schema) {
                    // only add non-empty values
                    let found_value = response.data[index][schema[key]]
                    if (typeof found_value === "string") {
                      if (found_value.length !== 0) {
                        lookupResponse[key] = found_value
                      }
                    } else {
                       lookupResponse[key] = found_value
                    }
                  }
                  if (Object.keys(lookupResponse).length > 0) {
                    lookupArray.push( lookupResponse )
                  }
                } else {
                  // what is return schema then?
                  lookupArray.push( this.convert(response.data[index], convert) )
                }
              }
              return lookupArray
            } else {
              if (typeof response.data == 'object' && typeof schema !== 'undefined') {
                // lookup data is an object, return the property we are looking for
                return this.convert(response.data[schema], convert)
              } else {
                console.log("lookup response is a bit weird " + response.data)
                return response.data
              }
            }
          })
          .catch(e => {
            this.error(e)
          })
      },
      getImage(name, url, payload, callback) {
        ajax.post(url, payload, { responseType: 'blob' })
          .then(function (response) {
            let reader = new FileReader();
            reader.onload = e => callback(name, e.target.result)
            reader.readAsDataURL(response.data); 
          });
      },
      setImage(name, data) {
        this.makeImageShow = name
        this.data[name] = data
      },
      showFormComponentAndSetDefault(formItem) {
        if (formItem) {
          const showComponent = this.showFormComponent(formItem)
          if (typeof formItem.default == "object") {
            if (this.data[formItem.name]) {
              if (typeof formItem.default.hide !== "undefined" && !showComponent) {
                this.$set(this.data, formItem.name, formItem.default.hide)
              } else if (typeof formItem.default.show !== "undefined" && showComponent) {
                this.$set(this.data, formItem.name, formItem.default.show)
              } else {
                console.log("Default settings for " + formItem.value + " are not right")
              }
            }
          }
          return showComponent
        }
      },
      showFormComponent(formItem) {
        if (this.addForm) {
          if (formItem.readonly || formItem.noadd || formItem.type == 'datetime') {
            return false
          }
        } else {
          if (formItem.noedit) {
            return false
          }
        }
        return this.showIf(formItem, this.data)
        // if (typeof formItem.showIf == 'object') {
        //   // value must be equal to
        //   if (formItem.showIf.operator == 'equalTo') {
        //     if (formItem.showIf.value !== this.data[formItem.showIf.name]) {
        //       return false
        //     }
        //   // value must not be equal to
        //   } else if (formItem.showIf.operator == 'notEqualTo') {
        //     if (formItem.showIf.value === this.data[formItem.showIf.name]) {
        //       return false
        //     }
        //   // value must not be empty or unset
        //   } else if (formItem.showIf.operator == 'notEmpty') {
        //     if (!this.data[formItem.showIf.name]) {
        //       return false
        //     }
        //   } else {
        //     if (typeof this.data[formItem.showIf.name] !== "undefined") {
        //       // value must be one of
        //       if (formItem.showIf.operator == 'oneOf') {
        //         if (!formItem.showIf.value.includes(this.data[formItem.showIf.name])) {
        //           return false
        //         }
        //       }
        //       // value must not be one of
        //       if (formItem.showIf.operator == 'notOneOf') {
        //         if (formItem.showIf.value.includes(this.data[formItem.showIf.name])) {
        //           return false
        //         }
        //       }
        //     }
        //   }
        // }
        // return true
      },
      setDatePicker(minMax, formItem) {
        if (typeof formItem.date !== "undefined") {
          if (typeof formItem.date.direction !== "undefined") {
            const today = new Date(Date.now());
            if (minMax == 'min' && formItem.date.direction == 'future') {
              return today.toISOString();
            }
            if (minMax == 'max' && formItem.date.direction == 'past') {
              return today.toISOString();
            }
          }
        }
        return null;
      },
      show(incoming) {
        alert(incoming)
      },
      buttonPush (index, formItem, value) {
        this.$set(this.buttonPushed, index, true)
        if (typeof formItem.url !== "undefined" || typeof formItem.get !== "undefined") {
          // a get request with parameters on the button push
          let url = this.url(formItem.url, value)
          ajax.get(url)
              .then(response => {
                if (typeof response.data.message != "undefined") {
                  this.$emit('dialog', "Success!", response.data.message)
                } else if (typeof response.data != "undefined") {
                  this.$emit('dialog', "Success!", response.data)
                } else {
                  this.$emit('dialog', "Success!")
                }
                this.$set(this.buttonPushed, index)
            })
              .catch(e => {
                this.error(e)
                this.$set(this.buttonPushed, index)
            })
        } else if (typeof formItem.put !== "undefined") {
          // a put request with parameters in the payload on the button push
          let url = this.url(formItem.put, value)
          let payload = {}
          if (formItem.payload) {
            payload = formItem.data = this.replaceParams(formItem.payload, this.data)
          }
          ajax.put(url, payload)
              .then(response => {
                if (typeof response.data.message != "undefined") {
                  this.$emit('dialog', "Success!", response.data.message)
                } else if (typeof response.data != "undefined") {
                  this.$emit('dialog', "Success!", response.data)
                } else {
                  this.$emit('dialog', "Success!")
                }
                this.$set(this.buttonPushed, index)
            })
              .catch(e => {
                this.error(e)
                this.$set(this.buttonPushed, index)
            })
        } else if (typeof formItem.post !== "undefined") {
          // a post request with parameters in the payload on the button push
          let url = this.url(formItem.post, value)
          let payload = {}
          if (formItem.payload) {
            payload = formItem.data = this.replaceParams(formItem.payload, this.data)
          }
          ajax.post(url, payload)
              .then(response => {
                if (typeof response.data.message != "undefined") {
                  this.$emit('dialog', "Success!", response.data.message)
                } else if (typeof response.data != "undefined") {
                  this.$emit('dialog', "Success!", response.data)
                } else {
                  this.$emit('dialog', "Success!")
                }
                this.$set(this.buttonPushed, index)
            })
              .catch(e => {
                this.error(e)
                this.$set(this.buttonPushed, index)
            })
        } else if (typeof formItem.link !== "undefined") {
          // link/launch/go off to a new page when the button is clicked
          let url = this.url(formItem.link, value)
          this.$set(this.buttonPushed, index)
          this.go(url)
        }
      },
      checkUrlParameters() {
        // this will insert the key/value pairs from the url into the data object
        this.$set(this.data, 'id', this.id)
        for (var key in this.$route.query) {
          this.$set(this.data, key, this.$route.query[key])
        }
      },
      setAutoCompleteURL(formItem) {
        let acURL
        if (formItem.autocomplete.id) {
          acURL = this.url(formItem.autocomplete.url, this.data[formItem.autocomplete.id])
        } else if (formItem.id) {
          acURL = this.url(formItem.autocomplete.url, this.data[formItem.id])
        } else if (formItem.autocomplete.filter) {
          acURL = this.url(formItem.autocomplete.url, this.data[formItem.autocomplete.filter])
        } else {
          acURL = this.url(formItem.autocomplete.url)
        }
        this.$set(this.urls, formItem.name, acURL)
      },
      add(url, data) {
        ajax.post(url, data)
          .then(response => {
            console.log(response.data)
            this.$emit('dialog', 'Added!', 'Added!', true)
        })
          .catch(e => {
            this.error(e)
        })
      },
      update(url, data, type) {
        if (typeof type === "string" && type === "patch") {
          ajax.patch(url, data)
            .then(response => {
              console.log(response.data)
              this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
            .catch(e => {
              this.error(e)
          })
        } else if (typeof type === "string" && type === "post") {
          ajax.post(url, data)
            .then(response => {
              console.log(response.data)
              this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
            .catch(e => {
              this.error(e)
          })
        } else {
          ajax.put(url, data)
            .then(response => {
              console.log(response.data)
              this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
            .catch(e => {               
              this.error(e)
          })
        }
      },
      save(add) {
        //
        // Save Form Data
        //
        this.$emit('dialog', 'Save', 'Saving...', 0)
        var theData = {}
        for (var property in this.data) {
          // If there is a matching form item for this property and the form item passes the following criteria:
          // - not readonly
          // - not a datetime type (like created_at and updated_at)
          // - is allowed to be used for "adding" when "adding"
          // - is allowed to be used for "editting" when "editting"
          // - 
          // and not READONLY, copy the data onto the submitted request
          var match = this.form.design.find(item => {
            return (item.key === property || item.value === property || item.name === property)
          });
          if (match && !match.readonly) {
            if ((!match.type || match.type != 'datetime')) {
              if ((add && !match.noadd) || (!add && !match.noedit)) {
                if (typeof this.data[property] !== "undefined" && this.data[property] !== null) {
                  if (match.key && typeof this.data[match.key] !== "undefined") {
                    // the 'key' has the data
                    theData[property] = this.data[match.key]

                  } else if (match.value && typeof this.data[property] !== "undefined") {
                    // the 'property' has the data
                    theData[property] = this.data[property]

                  } else if (match.name && typeof this.data[match.name] !== "undefined") {
                      // the 'name' has the data
                    theData[property] = this.data[match.name]

                  } else {
                    // the 'value' has the data
                    theData[property] = this.data[match.value]
                  }
                }
              }
            }
          }
        }
        if (add) {
          var addUrl = ''
          if (typeof this.form.add === "undefined") {
            addUrl = this.url(this.form.url, this.id)
          } else {
            addUrl = this.url(this.form.add, this.id)
          }
          // replace {} in the url using the id and the form data 
          // before submitting the data to the url
          this.add(addUrl, theData)
        } else {
          var updateUrl = ''
          if (typeof this.form.patch !== "undefined") {
            updateUrl = this.url(this.form.patch, this.id)
            this.update(updateUrl, theData, "patch")
          } else if (typeof this.form.post !== "undefined") {
            updateUrl = this.url(this.form.post, this.id)
            this.update(updateUrl, theData, "post")
          } else if (typeof this.form.update !== "undefined") {
            updateUrl = this.url(this.form.update, this.id)
            this.update(updateUrl, theData, "put")
          } else {
            updateUrl = this.url(this.form.url, this.id)
            this.update(updateUrl, theData, "put")
          }
        }
      },
      cancel() {
        this.$router.go(-1)
      }
    }
  }
</script>